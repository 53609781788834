<template>
    <input :value="value" @input="onInput" :placeholder="params.config.SEARCH_PLACEHOLDER">
</template>

<style scoped>
input {
  border: 1px solid var(--skp-color-neutral-300);
  background: var(--skp-color-white);
  border-radius: var(--skp-small-radius);
  color: var(--skp-color-neutral-900);
  font-family: var(--skp-main-font);
  display: block;
  outline: none;
  padding: 12px;
}
::placeholder {
  color: var(--skp-color-neutral-400);
}
</style>

<script>
export default {
  name: "Search",
  methods: {
    onInput (e) {
      this.$emit('input', e.target.value);
    },
  },
  props: ["value", "params"],
};
</script>
