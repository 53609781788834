<template>
  <div class="header_container">
    <h1 v-if="params.config.TITLE_DISPLAY" class="header_title noselect" :style="{color: params.config.COLOR_TITLE}">
      {{ params.config.TITLE }}
    </h1>
    <h2 v-if="params.config.DESCRIPTION_DISPLAY" class="header_subtitle noselect" :style="{color: params.config.COLOR_SUBTITLE}">
      {{ params.config.SUBTITLE }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["params"],
};
</script>
