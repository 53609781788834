<template>
  <main>
    <template v-if="route === 'menu'">
      <div v-if="params.config.TOP_BLOCK_DISPLAY" class="css-layout">
        <Header v-if="params.config.HEADER_DISPLAY" :params="params" />
        <div v-if="params.config.FILTERS_COUNT" class="filters">
          <FilterCategories v-if="params.config.CATEGORY_DISPLAY" :params="params" @input="event => onCategory(event)" />
          <Search v-if="params.config.SEARCH_DISPLAY" :params="params" v-model="search" />
        </div>
      </div>
      <CardsList
        v-if="!isLoading"
        :params="params"
        :videos="filteredVideos"
        @video="onVideo"
        @share="onShare"
        @product="onProduct"
        @page="onPage"
      />
      <div class="loader-container" v-else>
        <skp-loader type="secondary" size="large" ></skp-loader>
      </div>
    </template>
    <template v-else-if="route === 'video'">
      <Video
        :params="params"
        :ratings="ratings"
        :video="currentVideo"
        @menu="onMenu"
        @video="onVideo"
        @ambassador="onAmbassador"
        @rate="onRate"
        @share="onShare"
        @product="onProduct"
      />
    </template>
  </main>
</template>

<script>
import Search from "../components/search.vue";
import FilterCategories from "../components/filter_categories.vue";
import Header from "../components/header.vue";
import CardsList from "../components/cards_list.vue";
import Video from "../components/mobileVideo";
import { scrollUp, saveRating, saveShare, saveProduct, saveAmbassador, openVideoLink } from "../events";
import { formatExternalVideo, formatProjectVideo } from '../utils';
import { getExternalVideos, getVideos } from '../graphql';
import { allVideos } from '../main';

export default {
  name: "Mobile",
  props: ["params"],
  data() {
    return {
      isLoading: false,
      search: '',
      route: 'menu',
      currentVideo: null,
      ratings: {},
    };
  },
  components: {
    Header,
    CardsList,
    Video,
    FilterCategories,
    Search,
  },
  computed: {
    filteredVideos () {
      const search = this.search.trim();
      return search ? this.params.videos.filter((video) => video.title.toLowerCase().includes(search.toLowerCase())) : this.params.videos;
    },
  },
  methods: {
    async onCategory (event) {
      try {
        this.isLoading = true;
        const [
          { data: { getSolution: videos } = {} },
          { data: { externalVideos } = {} }
        ] = await Promise.all([
          getVideos(this.params.args.hash, event),
          getExternalVideos(this.params.args.hash, event)
        ]);
        this.isLoading = false;
        this.params.videos = event.length === 0
            ? allVideos
            : videos?.videos
                ?.map((video) => formatProjectVideo(video))
                .concat(externalVideos?.edges?.map((video) => formatExternalVideo(video)));
      } catch (_) {
        this.params.videos = allVideos;
      }
    },
    onVideo (video) {
      this.currentVideo = video;
      this.route = 'video';
      scrollUp();
    },
    onMenu () {
      this.route = 'menu';
    },
    onAmbassador (video) {
      saveAmbassador(video.hash);
    },
    onRate ({ video, rating }) {
      this.ratings = {
        ...this.ratings,
        [video.hash]: rating
      };
      saveRating(video.hash, rating);
    },
    onShare (video) {
      saveShare(video.hash);
    },
    onProduct (video) {
      window.open(video.url, "_blank");
      openVideoLink(video.url);
      saveProduct(video.hash);
    },
    onPage () {
      scrollUp();
    },
  },
};
</script>


<style scoped module>
@import "../assets/styles/layout.css";

main {
  padding: 1rem;
}

</style>
