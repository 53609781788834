<template>
  <main class="main-container">
    <div v-if="params.config.TOP_BLOCK_DISPLAY" class="css-layout">
      <Header v-if="params.config.HEADER_DISPLAY" :params="params" />
      <div v-if="params.config.FILTERS_COUNT" class="filters" :class="{ 'singleFilter':params.config.FILTERS_COUNT === 1 }">
        <FilterCategories v-if="params.config.CATEGORY_DISPLAY" :params="params" @input="event => onCategory(event)" />
        <Search v-if="params.config.SEARCH_DISPLAY" :params="params" v-model="search" />
      </div>
    </div>
    <CardsList
      v-if="!isLoading"
      :params="params"
      :videos="filteredVideos"
      @video="onVideo"
      @share="onShare"
      @product="onProduct"
      @page="onPage"
    />
    <div class="loader-container" v-else>
      <skp-loader type="secondary" size="large" ></skp-loader>
    </div>
  </main>
</template>

<style>
  @import "../assets/styles/layout.css";

</style>

<script>
import Header from "../components/header.vue";
import Search from "../components/search.vue";
import FilterCategories from "../components/filter_categories.vue";
import CardsList from "../components/cards_list.vue";
import { getSuggestions, openVideo, saveShare, saveProduct, scrollUp } from '../events';
import { formatExternalVideo, formatProjectVideo } from '../utils';
import { getVideos, getExternalVideos } from '../graphql';
import { allVideos } from '../main';

export default {
  name: "Desktop",
  props: ["params"],
  components: {
    Header,
    FilterCategories,
    Search,
    CardsList,
  },
  data () {
    return {
      isLoading: false,
      search: '',
    };
  },
  computed: {
    filteredVideos () {
      const search = this.search.trim();
      return search ? this.params.videos.filter((video) => video.title.toLowerCase().includes(search.toLowerCase())) : this.params.videos;
    },
  },
  methods: {
    async onCategory (event) {
      try {
        this.isLoading = true;
        const [
            { data: { getSolution: videos } = {} },
            { data: { externalVideos } = {} }
        ] = await Promise.all([
            getVideos(this.params.args.hash, event),
            getExternalVideos(this.params.args.hash, event)
        ]);
        this.isLoading = false;
        this.params.videos = event.length === 0
            ? allVideos
            : videos?.videos
                ?.map((video) => formatProjectVideo(video))
                .concat(externalVideos?.edges?.map((video) => formatExternalVideo(video)));
      } catch (_) {
        this.params.videos = allVideos;
      }
    },
    onVideo (video) {
      const suggestions = getSuggestions(video, this.params.videos);
      openVideo(video, suggestions, this.params.args.hash);
    },
    onShare (video) {
      saveShare(video.hash);
    },
    onProduct (video) {
      window.open(video.url, "_blank");
      saveProduct(video.hash);
    },
    onPage () {
      scrollUp();
    }
  },
};
</script>
