<template>
  <div v-if="paginatedVideos.length > 0">
    <div class="cards_container">
      <card
        v-for="(video, index) in paginatedVideos"
        :key="video.id"
        :class="{transition_start: !loaded, transition_end: loaded}"
        :style="{transition: `all 0.8s ease-in-out ${index / 20}s`}"
        :video="video"
        :params="params"
        @click="onClick"
        @share="onShare"
        @product="onProduct"
      />
    </div>
    <v-layout
      v-if="pages.pages.length > 1"
      justify-center
      align-center
      :style="{
        gap: '1em',
        marginBottom: '40px',
        minHeight: '60px',
        overflowX: 'auto',
        alignItems: 'center',
        color: params.config.COLOR_NAV,
      }"
    >
      <button v-if="pages.previousButton" @click="onPage(page - 1)">&lt;</button>
      <button v-if="pages.firstPage" @click="onPage(pages.firstPage.index)">{{ pages.firstPage.index }}</button>
      <span v-if="pages.leadingDots">...</span>
      <button
        v-for="page in pages.pages"
        :key="page.index"
        :style="{
          backgroundColor: page.current ? params.config.COLOR_ICON : 'white',
          color: page.current ? 'white' : 'inherit',
        }"
        @click="onPage(page.index)"
      >
        {{ page.index }}
      </button>
      <span v-if="pages.trailingDots">...</span>
      <button v-if="pages.lastPage" @click="onPage(pages.lastPage.index)">{{ pages.lastPage.index }}</button>
      <button v-if="pages.nextButton" @click="onPage(page + 1)">&gt;</button>
    </v-layout>
  </div>
  <div v-else id="empty">
    <empty />
    <h1>{{ params.config.EMPTY_TITLE }}</h1>
    <p>{{ params.config.EMPTY_DESCRIPTION }}</p>
  </div>
</template>

<style scoped>
.cards_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(231px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.transition_init {
  transition: all 0.8s ease-in-out;
}

.transition_start {
  transform: translate(-15px, 15px);
  opacity: 0;
}

.transition_end {
  transform: translate(0, 0);
  opacity: 100;
}

button {
  background-color: white;
  width: 3em;
  height: 3em;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 8px;
  outline: none;
}

#empty {
  text-align: center;
}

#empty svg {
  width: 40%;
  margin-bottom: 40px;
}
</style>

<script>
import empty from "../assets/empty.svg";
import card from "./card";

const perPage = 24;
const paginationSpan = 2;

export default {
  name: "CardsList",
  components: {
    card,
    empty,
  },
  props: ["params", "videos"],
  data() {
    return {
      loaded: false,
      page: 1,
    };
  },
  computed: {
    paginatedVideos () {
      return this.videos.slice().sort((x, y) => y.views - x.views).slice((this.page - 1) * perPage, this.page * perPage);
    },
    pages () {
      const totalPages = Math.ceil(this.videos.length / perPage);
      const pages = [this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2]
        .filter((page) => page > 0 && page <= totalPages)
        .map((index) => ({ index, current: this.page === index }));

      return {
        pages,
        firstPage: this.page - paginationSpan > 1 ? { index: 1, current: false } : null,
        lastPage: this.page + paginationSpan < totalPages ? { index: totalPages, current: false } : null,
        previousButton: this.page > 1,
        nextButton: this.page < totalPages,
        leadingDots: this.page - paginationSpan > 1,
        trailingDots: this.page + paginationSpan < totalPages,
      };
    },
  },
  watch: {
    /**
     * When the video list changes (e.g. when searching), go back to the first page
     */
    videos () {
      this.page = 1;
    },
    page (page) {
      this.$emit('page', page);
    },
  },
  methods: {
    onClick (video) {
      this.$emit('video', video);
    },
    onShare (video) {
      this.$emit('share', video);
    },
    onProduct (video) {
      this.$emit('product', video);
    },
    onPage (page) {
      if (page !== this.page) {
        this.loaded = false;
        setTimeout(() => {
          this.page = page;
            setTimeout(() => {
              this.loaded = true;
            }, 0);
        }, 800 + 50 * (this.paginatedVideos.length - 1));
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 0);
  }
};
</script>
